import Vue from "vue"
import { createI18n } from "vue-i18n"
import enUS from './langs/en'
import zhCN from "./langs/zhCN"


//vant 的语言包
import { Locale } from 'vant';
import v_enUS from 'vant/es/locale/lang/en-US'
import v_zhCN from 'vant/es/locale/lang/zh-CN'

//dayjs
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/en'


const i18n = createI18n({
	locale: localStorage.getItem('language') || 'en-US',
	fallbackLocale: 'en-US', //如果在 语言中未找到翻译 API中指定的关键资源，则要回退到 语言
	legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
	globalInjection: true, // 全局注册$t方法

	messages: {
		'en-US': enUS, // 英文语言包
		'zh-CN': zhCN,
	}
})



//vant 的语言包
Locale.add({
	'en-US': v_enUS,
	'zh-CN': v_zhCN
})
//设置默认
Locale.use('en-US');

//设置dayjs默认zh-cn en
dayjs.locale('en')

//更改语言，注意vant语言的key 和 i18n语言的key保持一致
function changeLanguage(lang) {
	i18n.global.locale.value = lang
	Locale.use(i18n.global.locale.value)
	localStorage.setItem('language', lang)
}

export { i18n, changeLanguage }
