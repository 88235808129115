// 一些可能是整个app都会用到的actions

import { defineStore } from "pinia";

const useActionsStore = defineStore("actions", {
  state: () => ({
    //二维码
    qrCode: {
      showQRCode: false,
      qrContent: "",
    },

    //需要缓存的tab
    tabCachedComponents: ['reception', 'statistics', 'performance']
  }),

  actions: {

    //清除tab keep-alive 的缓存
    clearTabCachedComponent(){
      this.tabCachedComponents = []
    },

    //重设tab keep-alive 的缓存
    recoverTabCachedComponent() {
      this.tabCachedComponents = ['reception', 'statistics', 'performance']
    }
  }
})

export default useActionsStore