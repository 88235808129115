import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './stores'

import "normalize.css"
import "./assets/css/index.css"
// 你可以在项目的入口文件或公共模块中引入 Dialog 组件的样式，这样在业务代码中使用 Dialog 时，便不再需要重复引入样式了。
import 'vant/es/dialog/style';
import 'vant/es/toast/style'
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';

import { i18n } from './i18n'






createApp(App).use(router).use(pinia).use(i18n).mount('#app')
