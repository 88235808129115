import { createRouter, createWebHashHistory, next } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [

    // -----------------------------reception-----------------------
    {
      path: "/",
      redirect: "/reception",
      meta: {
        showTabBar: true,
        tabbarActive: 0
      }
    },
    {
      name: "reception",
      path: "/reception",
      component: () => import("@/views/reception/reception.vue"),
      meta: {
        showTabBar: true,
        tabbarActive: 0
      }
    },
    {
      name: "createsession",
      path: "/createsession",
      component: () => import("@/views/reception/createsession.vue")
    },
    {
      name: "farewell",
      path: "/farewell",
      component: () => import("@/views/reception/farewell.vue")
    },
    {
      name: "disclaimer",
      path: "/player/disclaimer",
      component: () => import("@/views/player/disclaimer.vue")
    },
    {
      name: "signature",
      path: "/player/signature",
      component: () => import("@/views/player/signature.vue")
    },
    {
      name: "editinfo",
      path: "/player/editinfo",
      component: () => import("@/views/player/editinfo.vue")
    },

    {
      name: "complete",
      path: "/player/complete",
      component: () => import("@/views/player/signaturecomplete.vue")
    },


    // -----------------------------statistics-----------------------
    {
      name: "statistics",
      path: "/statistics",
      component: () => import("@/views/statistics/statistics.vue"),
      meta: {
        showTabBar: true,
        tabbarActive: 1
      }
    },
    // {
    //   name: "records",
    //   path: "/statistics/records",
    //   component: () => import("@/views/statistics/records.vue"),
    // },




    // -----------------------------performance-----------------------
    {
      name: "performance",
      path: "/performance",
      component: () => import("@/views/performance/performance.vue"),
      meta: {
        showTabBar: true,
        tabbarActive: 2
      }
    },



    // -----------------------------personal-----------------------
    {
      name: "personal",
      path: "/personal",
      component: () => import("@/views/personal/personal.vue"),
      meta: {
        showTabBar: true,
        tabbarActive: 3
      }
    },

    {
      name: "login",
      path: "/login",
      component: () => import("@/views/personal/login.vue")
    },

    {
      path: "/:pathMatch(.*)*",
      component: () => import("@/views/personal/notfound.vue")
    }
  ],



})


//清除路由记录
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}



export default router