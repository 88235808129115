export default {

	cancel: "Cancel",
	confirm: "Confirm",
	empty_description: 'No data available',
	no_more:"No more",

	//reception
	reception: {
		fullTime: "Select full time",
		dp_title:"Delete current player information",
		dp_desc: "Are you sure you want to delete the current player's match information?",

		//选择单个日期
		single_data: "Select a date",
		attention:"Attention: ",
		today_tip:`This page displays today's session records. You can view more records in "History" or log in to the computer management console to view more complete data records and statistics.`,
		yesterday_tip: `This page displays yesterday's session records. You can view more records in "History" or log in to the computer management console to view more complete data records and statistics.`,

		//所选日期没有场次纪录
		no_session:"There is no record of the selected date",
	},

	//statistics
	statistics: {
		no_performance: "There are no actors in the currently selected range, so there is no performance data to show you.",
	},

	//performance
	performance: {
		no_performance: "No points record for you was found during the current time period.",
	},


	//personal
	personal: {
		adm: "Super Administrator",
		manager: "Store Manager",
		clerk: "Clerk",

		email_placeholder:"Please enter email",
	},


	menu: {

	},

	role:{
		superAdministrator:"Super Administrator",
		administrator: "Administrator",
		storeManager: "Store Manager",
		staff: "Staff",
		actors: "Actors",
		observer: "Observer",

	},


	toast: {
		no_store:"Please select a store",
		save_success:"Saved Successfully",
		choose_file:"Choose file",
		upload_success: "Upload Successfully",
		send_success: "Send Successfully",
	},

}
