import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app"
};
import { useRoute } from "vue-router";
import tabBar from "./components/tab-bar.vue";
import { storeToRefs } from "pinia";
import useActionsStore from "./stores/modules/actions";
export default {
  __name: 'App',
  setup(__props) {
    const route = useRoute();
    const actionsStore = useActionsStore();
    //手动清除keep-alive 缓存的影响
    const {
      tabCachedComponents
    } = storeToRefs(actionsStore);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_qr_code = _resolveComponent("qr-code");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, null, {
        default: _withCtx(props => [(_openBlock(), _createBlock(_KeepAlive, {
          include: _unref(tabCachedComponents)
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent(props.Component)))], 1032, ["include"]))]),
        _: 1
      }), _withDirectives(_createVNode(tabBar, null, null, 512), [[_vShow, _unref(route).meta.showTabBar]]), _createVNode(_component_qr_code)]);
    };
  }
};