export default {

  cancel: "取消",
  confirm: "确认",
  empty_description: '暂无数据',
  no_more: "没有更多了",

  //reception
  reception: {
    fullTime: "选择完整时间",
    dp_title: "删除当前玩家信息",
    dp_desc: "是否确定删除当前玩家的场次信息？",

    //选择单个日期
    single_data: "选择单个日期",
    attention: "注意: ",
    today_tip: `本页显示今天的场次纪录。你可以在“历史纪录”中查看更多纪录，或者登录电脑端管理后台，查看更完整的数据纪录和统计。`,
    yesterday_tip: `本页显示昨天的场次纪录。你可以在“历史纪录”中查看更多纪录，或者登录电脑端管理后台，查看更完整的数据纪录和统计。`,

    //所选日期没有场次纪录
    no_session: "所选日期没有场次纪录",
  },

  //statistics
  statistics: {
    no_performance: "当前选择的范围内没有任何演员，所以没有演艺数据可与你展示。",
  },

  //performance
  performance: {
    no_performance: "当前时间段内，没有找到你的积分纪录。",
  },


  //personal
  personal: {
    adm: "超级管理员",
    manager: "门店管理员",
    clerk: "员工",
    email_placeholder: "请输入邮箱",


  },


  menu: {

  },

  role: {
    superAdministrator: "超级管理员",
    administrator: "管理员",
    storeManager: "店长",
    staff: "员工",
    actors: "演员",
    observer: "观察者",

  },



  toast: {
    no_store: "请选择一个门店",
    save_success: "保存成功",
    choose_file: "选择文件",
    upload_success: "上传成功",
    send_success: "发送成功",

  },

}
